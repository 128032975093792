.schedule-cell-dimension,
.schedule-cell-dimension.e-schedule .e-table-wrap .e-content-wrap > table {
    min-height: 650px;
}

.schedule-cell-dimension.e-schedule .e-month-view .e-current-date .e-date-header {
    background-color: var(--celestino);
    width: 25px;
    padding: 3px;
}

.schedule-cell-dimension.e-schedule .e-vertical-view .e-header-cells,
.schedule-cell-dimension.e-schedule .e-month-view .e-date-header-wrap table td {
    font-weight: bold;
}

.schedule-cell-dimension.e-schedule .e-vertical-view .e-header-cells.e-current-day,
.schedule-cell-dimension.e-schedule .e-month-view .e-date-header-wrap table td.e-current-day {
    color: var(--prussia);
}

.schedule-cell-dimension.e-schedule .e-table-wrap .e-content-wrap {
    height: 100% !important;
}

.schedule-cell-dimension.e-schedule .e-vertical-view.e-timescale-disable .e-appointment,
.schedule-cell-dimension.e-schedule .e-vertical-view.e-timescale-disable .e-appointment:focus {
    height: 180px;
    padding: 1rem .5rem;
    background: transparent;
    color: var(--opale);
    cursor: default;
    border: none !important;
    box-shadow: none !important;
}

.schedule-cell-dimension.e-schedule .e-vertical-view.e-timescale-disable .e-appointment .e-appointment-details {
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    cursor: pointer;
    box-shadow: -0.35rem .25rem 0.9rem 0rem var(--shadow);
    border-radius: var(--roundedl-m);
}

.schedule-cell-dimension.e-schedule .e-vertical-view.e-timescale-disable .e-appointment .e-appointment-details > div {
    width: 100%;
    height: 100%;
}

.schedule-cell-dimension.e-schedule .e-schedule-toolbar .e-toolbar-item.e-today,
.schedule-cell-dimension.e-schedule .e-schedule-toolbar .e-toolbar-item.e-today + div,
.schedule-cell-dimension.e-schedule .e-month-view .e-appointment .e-appointment-details .e-icons,
.schedule-cell-dimension.e-schedule .e-vertical-view .e-appointment .e-appointment-details > .e-indicator.e-icons {
    display: none;
}

.schedule-cell-dimension.e-schedule .e-schedule-toolbar .e-toolbar-item {
    margin: 0 .25rem;
}

.schedule-cell-dimension.e-schedule .e-schedule-toolbar .e-toolbar-item.e-views button{
    padding: 0 1rem;
}

.schedule-cell-dimension.e-schedule .e-schedule-toolbar .e-toolbar-item.e-views.e-active-view button{
    background-color: var(--prussia);
    border-radius: var(--rounded-m);
    height: 100%;
}

.schedule-cell-dimension.e-schedule .e-schedule-toolbar .e-toolbar-item.e-views.e-active-view button .e-tbar-btn-text {
    color: var(--white);
}

/* .schedule-cell-dimension.e-schedule .e-month-view .e-appointment {
    background: var(--prussia);
} */

.schedule-cell-dimension.e-schedule .e-calendar .e-content td.e-today.e-selected span.e-day,
.schedule-cell-dimension.e-schedule .e-calendar .e-content .e-selected span.e-day {
    background-color: var(--prussia) !important;
    border-color: var(--prussia) !important;
    color: var(--white) !important;
}

.schedule-cell-dimension.e-schedule .e-calendar .e-content td.e-focused-date.e-today span.e-day {
    border-color: var(--prussia);
    color: var(--prussia);
}

.schedule-cell-dimension.e-schedule .e-calendar .e-content td.e-today span.e-day {
    border-color: var(--prussia) !important;
    color: var(--prussia) !important;
}

.schedule-cell-dimension.e-schedule .e-btn.e-today.e-flat.e-primary.e-css {
    background-color: var(--prussia);
    color: var(--white);
}

.schedule-cell-dimension.e-schedule .e-date-header.e-navigate {
    height: 25px;
}

.schedule-cell-dimension.e-schedule .e-month-view .e-appointment .e-appointment-details > div {
    width: 100%;
}

.schedule-cell-dimension.e-schedule .e-month-view .e-appointment {
    height: 30px;
}

.schedule-cell-dimension.e-schedule .e-month-view .e-appointment .e-appointment-details {
    line-height: 28px;
}

.schedule-cell-dimension.e-schedule .e-quick-popup-wrapper .e-event-popup .e-popup-content {
    padding:0
}
.schedule-cell-dimension .e-quick-popup-wrapper .e-event-popup .e-popup-header,
.schedule-cell-dimension .e-quick-popup-wrapper .e-event-popup .e-popup-footer {
    display: none;
}

.schedule-cell-dimension .e-quick-popup-wrapper {
    background: transparent;
    border: none;
    box-shadow: none;
}

.e-dlg-container.e-dlg-center-center {
    z-index: 1030 !important;
}

.quick-info.e-dialog.e-popup {
    width: 40vw;
    height: 80vh;
    border-radius: var(--rounded-l);
}

.quick-info.e-dialog .e-dlg-header-content {
    height: 0;
    border-radius: var(--roundedt-l);
}

.quick-info.e-dialog .e-dlg-content {
    padding: 0rem 1.5rem 0rem 1.5rem;
    margin-bottom: 1.5rem;
    border-radius: var(--rounded-l);
}
.quick-info.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
    top: -10px;
}

.quick-info .image {
    width: 165px;
    height: 165px;
    object-fit: cover;
}

.quick-info .header {
    flex: 1 1 auto;
}

.quick-info .content,
.quick-info .trigger-active {
    flex: 1 1 100%
}

.quick-info .restaurant-container {
    height: auto;
}

.quick-info + .e-dlg-overlay {
    background-color: var(--overlay);
}

.e-delete-promo:before {
    content: "\e94a";
    color: var(--red);
    cursor: pointer;
    font-size: 17px;
    position: relative;
    bottom: 1px;
}

.e-update-promo {
    border: 1px solid var(--opale);
    border-radius: 50%;
    padding: 0 2px 2px 2px;
}

.e-update-promo:before {
    content: "\e338";
    font-size: 10px;
    cursor: pointer;
}

.delete-promo-popup.e-confirm-dialog.e-dialog.e-popup {
    margin-top: 15vh !important;
    width: 18vw;
    left: 3vw !important;
}

.delete-promo-popup.e-confirm-dialog.e-dialog.e-popup #_dialog-header {
    border-radius: var(--roundedt-l);
}

.delete-promo-popup.e-confirm-dialog.e-dialog.e-popup .e-footer-content {
    border-radius: var(--roundedb-l);
}

.delete-promo-popup.e-confirm-dialog.e-dialog.e-popup .e-dlg-header-content,
.delete-promo-popup.e-confirm-dialog.e-dialog.e-popup .e-dlg-content, 
.delete-promo-popup.e-confirm-dialog.e-dialog.e-popup .e-footer-content {
    background-color: var(--alert-red);
}

.delete-promo-popup.e-confirm-dialog.e-dialog.e-popup #_title {
    font-weight: bold;
    color: var(--opale);
}

.delete-promo-popup.e-confirm-dialog.e-dialog.e-popup #_dialog-content {
    color: var(--opale);
}

.delete-promo-popup.e-confirm-dialog.e-dialog.e-popup .e-btn {
    text-transform: unset;
}
@font-face {
    font-family: "Open Sans";
    src: url("./OpenSans/OpenSans-Bold.ttf") format("truetype")
}
@font-face {
    font-family: "Open Sans";
    src: url("./OpenSans/OpenSans-Light.ttf") format("truetype")
}

@font-face {
    font-family: "Avenir";
    src: url("./Avenir/AvenirNextCyr-Bold.ttf") format("truetype")
}
@font-face {
    font-family: "Avenir";
    src: url("./Avenir/AvenirNextCyr-Regular.ttf") format("truetype")
}

@font-face {
    font-family: "Helvetica Neue";
    src: url("./HelveticaNeue/HelveticaNeueBold.ttf") format("truetype")
}
@font-face {
    font-family: "Helvetica Neue";
    src: url("./HelveticaNeue/HelveticaNeue-Light.otf") format("truetype")
}

@font-face {
    font-family: "Montserrat";
    src: url("./Montserrant/Montserrat-ExtraBold.ttf") format("truetype")
}
@font-face {
    font-family: "Montserrat";
    src: url("./Montserrant/Montserrat-Regular.ttf") format("truetype")
}

@font-face {
    font-family: "Roboto";
    src: url("./Roboto/Roboto-Black.ttf") format("truetype")
}
@font-face {
    font-family: "Roboto";
    src: url("./Roboto/Roboto-Medium.ttf") format("truetype")
}
@font-face {
    font-family: "Roboto";
    src: url("./Roboto/Roboto-Light.ttf") format("truetype")
}

/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,500;1,900&display=swap'); */
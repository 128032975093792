.multimedia-cropper-vertical .crop-container,
.multimedia-cropper-vertical .image-cropped,
.multimedia-video-vertical {
    width: 236px;
    height: 420px;
}

.multimedia-cropper-horizontal .crop-container,
.multimedia-cropper-horizontal .image-cropped,
.multimedia-video-horizontal {
    width: 520px;
    height: 293px;
}

.multimedia-cropper-horizontal .reactEasyCrop_Container,
.multimedia-cropper-vertical .reactEasyCrop_Container {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: var(--rounded-l);
}

.multimedia-cropper-horizontal .save-file-button.e-btn,
.multimedia-cropper-horizontal .edit-file-button.e-btn,
.multimedia-cropper-vertical .save-file-button.e-btn,
.multimedia-cropper-vertical .edit-file-button.e-btn {
    border: 1px solid var(--white);
    position: absolute;
    top: 240px;
    left: 0;
    right: 0;
    margin-left: auto !important;
    margin-right: auto !important;
}

.multimedia-cropper-horizontal .edit-file-button.e-btn,
.multimedia-cropper-vertical .edit-file-button.e-btn {
    border: 1px solid var(--prussia);
    padding-top: 4px;
}

.multimedia-cropper-vertical .save-file-button.e-btn,
.multimedia-cropper-vertical .edit-file-button.e-btn {
    top: 365px;
}

.multimedia-cropper-horizontal .delete-file-button.e-btn,
.multimedia-cropper-vertical .delete-file-button.e-btn,
.multimedia-video-horizontal .delete-file-button.e-btn,
.multimedia-video-vertical .delete-file-button.e-btn {
    border: 1px solid var(--white);
    position: absolute;
    right: 25px;
    top: 10px;
    margin-left: auto !important;
    margin-right: auto !important;
}

.multimedia-uploader-horizontal .custom-uploader .e-file-select-wrap,
.multimedia-uploader-vertical .custom-uploader .e-file-select-wrap {
    text-align: center;
}

.multimedia-uploader-horizontal .custom-uploader .e-upload-files,
.multimedia-uploader-vertical .custom-uploader .e-upload-files {
    display: none;
}

.multimedia-uploader-horizontal .custom-uploader.e-upload .e-file-select-wrap .e-file-drop,
.multimedia-uploader-horizontal .custom-uploader.e-upload .e-file-select-wrap button,
.multimedia-uploader-vertical .custom-uploader.e-upload .e-file-select-wrap .e-file-drop,
.multimedia-uploader-vertical .custom-uploader.e-upload .e-file-select-wrap button {
    font-size: 12px;
}

.multimedia-uploader-horizontal .custom-uploader.e-upload .e-file-select-wrap button,
.multimedia-uploader-vertical .custom-uploader.e-upload .e-file-select-wrap button {
    background-color: var(--prussia);
    color: var(--white);
}

.custom-uploader.disabled {
    background: transparent !important;
}

.custom-uploader.disabled button {
    background: transparent !important;
    background: #dddddd !important;
    color: var(--opale) !important;
}

.edit-file-button span::before {
    content: "\e338";
    font-family: "e-icons";
    margin-right: 5px;
    font-size:12px;
}
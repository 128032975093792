.promo-text-color-picker .e-btn.e-icon-btn {
    background: transparent;
    border: 1px solid var(--celestino);
    border-right: none;
}

.promo-text-color-picker .e-split-colorpicker.e-btn.e-icon-btn > span {
    box-shadow: 0px 0px 1px 1px var(--shadow);
}

.promo-text-color-picker .e-split-btn-wrapper .e-btn.e-icon-btn:last-child {
    border-right: 1px solid var(--celestino);
}

.promo-text-color-picker .e-dropdown-btn .e-caret::before, 
.promo-text-color-picker .e-dropdown-btn.e-btn .e-caret::before {
    color: var(--celestino);
}

.promo-text-color-picker .e-ctrl-btn .e-btn.e-btn.e-flat.e-primary.e-apply {
    background-color: var(--prussia);
    color: var(--white);
    padding: 5px 15px;
    margin-right: 10px;
}

.promo-text-color-picker .e-ctrl-btn .e-btn.e-cancel {
    color: var(--red) !important;
    border: 1px solid var(--red) !important;
    background-color: var(--white) !important;
    padding: 5px 15px;
}

.promo-text-color-picker .e-float-text.e-label-top {
    font-weight: bold !important;
}

.promo-text-dropdown.e-input-group input.e-input.e-dropdownlist,
.promo-text-dropdown.e-input-group.e-input-focus input.e-input.e-dropdownlist {
    height: 19px;
    min-height: unset;
    padding: 0;
}

.promo-text-dropdown.e-ddl.e-input-group.e-control-wrapper .e-ddl-icon::before {
    color: var(--white);
}

.e-input-group.e-control-wrapper.promo-text-dropdown.e-ddl.e-lib.e-keyboard {
    border: 1px solid var(--celestino) !important;
    border-radius: var(--rounded-m);
    max-width: 140px;
}

.e-input-group.e-control-wrapper.promo-text-dropdown.e-ddl.e-lib.e-keyboard input {
    padding: 0 5px;
}

.promo-text-dropdown.e-input-group.e-control-wrapper .e-input-group-icon,
.promo-text-dropdown.e-input-group.e-control-wrapper .e-input-group-icon:hover {
    height: 19px;
    border-radius: var(--roundedr-s);
    background-color: var(--prussia) !important;
    margin: 0;
}

.promo-text-dropdown.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.promo-text-dropdown.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.promo-text-dropdown.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.promo-text-dropdown.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.promo-text-dropdown.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.promo-text-dropdown.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.promo-text-dropdown.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.promo-text-dropdown.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
    background: none;
}

.e-btn-group.style-button-group {
    box-shadow: none;
    border: 1px solid var(--celestino);
    max-width: 140px;
}

#style-title,
#style-subtitle {
    padding: 0;
    box-shadow: none;
    height: 21px;
}
#style-title .e-btn,
#style-subtitle .e-btn {
    background-color: var(--white);
    border: none;
    display: flex;
}

#style-title .e-btn span,
#style-subtitle .e-btn span {
    color: var(--prussia);
    margin: 2px 0;
    height: 15px;
}

#style-title input:checked + .e-btn,
#style-subtitle input:checked + .e-btn {
    background-color: var(--prussia);
}

#style-title input:checked + .e-btn span,
#style-subtitle input:checked + .e-btn span {
    color: var(--white);
}

label.style-box-rounded:first-of-type {
    border-radius: 4px 0 0 4px;
}

label.style-box-rounded:last-of-type {
    border-radius: 0 4px 4px 0;
}

#style-title .e-btn span,
#style-subtitle .e-btn span {
    border-right: 1px solid var(--celestino);
    flex: 1 1 100%;
}

#style-title .e-btn:last-child span,
#style-subtitle .e-btn:last-child span {
    border-right: none;
}

.e-btn.modifier-button,
.e-btn.modifier-button:hover,
.e-btn.modifier-button:focus {
    border: 2px solid var(--prussia);
}

.col.mw-config-col {
    max-width: 190px;
}

.save-modification-button img {
    width: 18px;
    height: 18px;
    margin-right: 0.25rem;
    position: relative;
    bottom: 2px;
}

.modifier-button span::before {
    content: "\e338";
    font-family: "e-icons";
    margin-right: 5px;
    font-size: 12px;
}

.e-bold:before { content:'\e339'; }
.e-italic:before { content:'\e35a'; }
.e-underline:before { content:'\ec17'; }
.e-line-through:before { content:'\ec18'; }
/* VERTICAL */

.navbar-vertical {
    position: fixed;
    top: var(--nav-spacing);
    left: 0;
    width: var(--nav-spacing);
    padding-top: 3rem;
}

.navbar-vertical .navbar-item {
    height: calc(var(--nav-spacing) - 10px);
}

.navbar-vertical .navbar-item:hover,
.navbar-vertical .navbar-item.active {
    box-shadow: inset .25rem 0 0 var(--prussia);
}

.navbar-vertical .navbar-item .navbar-icon {
    width: calc(var(--nav-spacing) - 1.5rem);
    height: calc(var(--nav-spacing) - 1.5rem);
}

.navbar-vertical .navbar-item:hover .navbar-icon,
.navbar-vertical .navbar-item.active .navbar-icon {
    opacity: unset;
}

/* HORIZONTAL */

.navbar-horizontal {
    height: var(--nav-spacing);
}

.navbar-horizontal .navbar-item.logo {
    margin-left: calc(var(--nav-spacing) + 30px);
}

.nav-name-user {
    border-radius: 50%; 
    background-color: lightgrey;
    width: 25px;
    height: 25px;
}

.quitter {
    padding: 18px 0;
    border-left: 1px solid var(--shadow);
}

.logout {
    text-decoration: none !important;
}
.multimedia-cropper-vertical .slider-wrap .e-slider-track,
.multimedia-cropper-horizontal .slider-wrap .e-slider-track {
    border-radius: var(--rounded-l);
}

.multimedia-cropper-vertical .slider-wrap .e-control-wrapper.e-slider-container .e-slider .e-handle,
.multimedia-cropper-vertical .slider-wrap .e-control-wrapper.e-slider-container .e-slider .e-range,
.multimedia-cropper-horizontal .slider-wrap .e-control-wrapper.e-slider-container .e-slider .e-handle,
.multimedia-cropper-horizontal .slider-wrap .e-control-wrapper.e-slider-container .e-slider .e-range {
    background-color: var(--prussia);
    border-color: var(--prussia);
}

.multimedia-cropper-vertical .slider-wrap .e-control-wrapper.e-slider-container.e-horizontal,
.multimedia-cropper-orizontal .slider-wrap .e-control-wrapper.e-slider-container.e-horizontal {
    padding: 0 1rem;
}


.multimedia-cropper-horizontal .slider-wrap {
    width: 520px;
}

.multimedia-cropper-vertical .slider-wrap {
    width: 236px;
}
.e-accordion .e-acrdn-item.type-promo-list .e-acrdn-panel .e-acrdn-content,
.e-accordion .e-acrdn-item.trigger-list .e-acrdn-panel .e-acrdn-content {
    padding: 0 .25rem !important;
}

.content-page .e-accordion { border: none; }
.content-page .e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-panel .e-acrdn-content {
    padding: 0;
}

.pl-custom-header {
    padding: 0;
    padding-left: 30px !important;
}

.check-site.disabled,
.check-sites.disabled,
.check-restaurant.disabled,
.check-restaurants.disabled,
.check-types-promo.disabled {
    background-color: var(--white)!important;
}

.check-site.disabled .e-icons.e-frame.e-check,
.check-sites.disabled .e-icons.e-frame,
.check-restaurant.disabled .e-icons.e-frame.e-check,
.check-restaurants.disabled .e-icons.e-frame,
.check-types-promo.disabled .e-icons.e-frame {
    background-color: #3363e55e;
    border-color: transparent !important;

}

.custom-switch-component.e-switch-wrapper[aria-checked="false"],
.custom-switch-component.e-switch-wrapper[aria-checked="true"] {
    height: 22px;
    width: 44px;
    border: 2px solid #3363e580;
    border-radius: 20px;
    padding: 2px;
}

.custom-switch-component.e-switch-wrapper[aria-checked="true"] {
    border: 2px solid #3363e5;
}

.custom-switch-component.e-switch-wrapper .e-switch-handle,
.custom-switch-component.e-switch-wrapper:not(.e-switch-disabled):hover .e-switch-handle:not(.e-switch-active) {
    background-color: #3363e580;
    height: 16px;
    width: 16px;
    left: 1px;
    box-shadow: none;
}

.custom-switch-component.e-css.e-switch-wrapper .e-switch-handle.e-switch-active, 
.custom-switch-component.e-switch-wrapper .e-switch-handle.e-switch-active {
    left: 100% !important;
}

.custom-switch-component.e-switch-wrapper .e-switch-handle.e-switch-active,
.custom-switch-component.e-switch-wrapper:hover .e-switch-handle.e-switch-active {
    background-color: var(--white);
}

.custom-switch-component.e-switch-wrapper .e-switch-inner.e-switch-active .e-switch-on,
.custom-switch-component.e-switch-wrapper:hover .e-switch-inner.e-switch-active .e-switch-on {
    background-color: var(--prussia);
    opacity: 1;
}

.custom-switch-component.e-switch-wrapper .e-switch-off {
    background-color: var(--white);
    opacity: 1;
}

.check-types-promo.e-checkbox-wrapper .e-frame,
.check-restaurants.e-checkbox-wrapper .e-frame,
.check-sites.e-checkbox-wrapper .e-frame,
.check-restaurant.e-checkbox-wrapper .e-frame,
.check-site.e-checkbox-wrapper .e-frame {
    border: 1px solid;
}

.check-types-promo.e-checkbox-wrapper.disabled .e-frame.e-check,
.check-restaurants.e-checkbox-wrapper.disabled .e-frame.e-check,
.check-sites.e-checkbox-wrapper.disabled .e-frame.e-check,
.check-restaurant.e-checkbox-wrapper.disabled .e-frame.e-check,
.check-site.e-checkbox-wrapper.disabled .e-frame.e-check {
    background: #3363e580;
}

.check-types-promo.e-checkbox-wrapper .e-frame.e-check,
.check-restaurants.e-checkbox-wrapper .e-frame.e-check,
.check-sites.e-checkbox-wrapper .e-frame.e-check,
.check-restaurant.e-checkbox-wrapper .e-frame.e-check,
.check-site.e-checkbox-wrapper .e-frame.e-check,
.check-types-promo.e-checkbox-wrapper:hover .e-frame.e-check,
.check-restaurants.e-checkbox-wrapper:hover .e-frame.e-check,
.check-sites.e-checkbox-wrapper:hover .e-frame.e-check,
.check-restaurant.e-checkbox-wrapper:hover .e-frame.e-check,
.check-site.e-checkbox-wrapper:hover .e-frame.e-check,
.check-types-promo.e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-check,
.check-restaurants.e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-check,
.check-sites.e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-check,
.check-restaurant.e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-check,
.check-site.e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-check {
    background: var(--prussia);
}

.border.borderb {
    border-bottom: 3px solid #dee2e6!important;
}

.type-promo-list {
    padding-left: 80px;
}
.form-promo-tab.e-tab .e-tab-header .e-toolbar-items {
    border: 2px solid var(--prussia);
    border-radius: var(--rounded-m);
    width: 300px;
    min-height: 28px;
}

.form-promo-tab.e-tab .e-tab-header .e-indicator {
    display: none;
}

.form-promo-tab.e-tab .e-tab-header:not(.e-vertical) .e-toolbar-item.e-active {
    background-color: var(--prussia);
}

.form-promo-tab.e-tab .e-tab-header:not(.e-vertical) .e-toolbar-item {
    background-color: var(--white);
}

.form-promo-tab.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover {
    background-color: transparent;
    color: var(--prussia);
}

.form-promo-tab.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-text,
.form-promo-tab.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text {
    color: var(--white);
}

.form-promo-tab.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-text,
.form-promo-tab.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
    color: var(--prussia);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
}

.form-promo-tab.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-text {
    text-transform: capitalize;
}

.form-promo-tab.e-tab .e-tab-header .e-item.e-toolbar-item.e-active,
.form-promo-tab.e-tab .e-tab-header .e-item.e-toolbar-item.e-active:last-child {
    border-radius: 0;
}

.form-promo-tab.e-tab .e-tab-header .e-item.e-toolbar-item {
    border-radius: var(--roundedl-m);
    width: 50%;
    height: 28px;
    min-height: 28px;
}

.form-promo-tab.e-tab .e-tab-header .e-item.e-toolbar-item:last-child {
    border-radius: var(--roundedr-m);
}

.form-promo-tab.e-tab .e-tab-header .e-toolbar-item .e-text-wrap,
.form-promo-tab.e-tab  .e-item.e-toolbar-item.e-active .e-text-wrap {
    height: 100%;
}

.form-promo-tab.e-tab .e-tab-header {
    min-height: 28px;
}
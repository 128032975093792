.screen-vertical {
    width: 236px;
    height: 420px;
}

.screen-horizontal {
    width: 520px;
    height: 293px;
}

.screen-vertical .subtitle,
.screen-vertical .title,
.screen-horizontal .subtitle,
.screen-horizontal .title {
    text-align: center;
    resize: none;
    background-color: transparent;
    border: none;
}

.screen-vertical .subtitle::placeholder,
.screen-vertical .title::placeholder,
.screen-horizontal .subtitle::placeholder,
.screen-horizontal .title::placeholder {
    color: var(--white);
} 

.promo-text-field.px-textarea-horizontal {
    padding-left: 13px !important;
    padding-right: 13px !important;
}
.promo-text-field.px-textarea-vertical {
    padding-left: 11px !important;
    padding-right: 11px !important;
}
.promo-text-field.mb-textarea-horizontal {
    margin-bottom: 5px;
}
.promo-text-field.mb-textarea-vertical {
    margin-bottom: 4px;
}


.screen-vertical .subtitle.disabled,
.screen-vertical .title.disabled,
.screen-horizontal .subtitle.disabled,
.screen-horizontal .title.disabled {
    pointer-events: none;
    background: transparent !important;
}

.alert-preview {
    width: 490px;
    border: 1px solid var(--alert);
}

.alert-preview .image {
    width: 30px;
    height: 30px;
}
.e-appointment-week-field {
    flex: 1 1;
}

.e-appointment-week-field.separator {
    flex: 0 1 10px;
}

.e-appointment-week-field.slim,
.e-appointment-week-field.image {
    flex: 0 1;
}

.e-appointment-image {
    height: 105px;
    width: 125px;
}

.custom-badge {
    border-radius: 50%;
    padding: 2px;
}

.width-calc-container {
    width: calc(100% - 2rem - 135px) !important;
}

@media (max-width: 1199px) {
    .width-calc-container.no-image-responsive {
        width: calc(100% - 10px) !important;
    }
}

.width-calc-container-no-image {
    width: calc(100% - 10px) !important;
}
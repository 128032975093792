@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-layouts/styles/material.css";

:root {
    --app: #ECF0FD;
    --white: #FFFFFF;
    --prussia: #3361E5;
    --celestino: #76B7FF;
    --carotina: #FF6700;
    --opale: #363636;
    --red: #EE383F;
    --alert-red: #FEE1E1;
    --green: #00D079;
    --alert-yellow: #FFF1CB;
    --overlay: #0a0b24;
    --shadow: #3636361f;
    --disabled: #dddddd;

    --nav-spacing: 60px;

    --rounded-l: 14px;
    --roundedl-l: 14px 0 0 14px;
    --roundedr-l: 0 14px 14px 0;
    --roundedt-l: 14px 14px 0 0;
    --roundedb-l: 0 0 14px 14px;

    --rounded-m: 5px;
    --roundedl-m: 5px 0 0 5px;
    --roundedr-m: 0 5px 5px 0;

    --roundedl-s: 3px 0 0 3px;
    --roundedr-s: 0 3px 3px 0;
}

body * {
    font-family: 'Roboto';
}

.font-size-11 { font-size: 8px !important; }
.font-size-12 { font-size: 9px !important; }
.font-size-13 { font-size: 10px !important; }
.font-size-14 { font-size: 11px !important; }
.font-size-16 { font-size: 12px !important; }
.font-size-18 { font-size: 13px !important; }
.font-size-19 { font-size: 14px !important; }
.font-size-20 { font-size: 15px !important; }
.font-size-22 { font-size: 16px !important; }
.font-size-23 { font-size: 17px !important; }
.font-size-24 { font-size: 18px !important; }
.font-size-25 { font-size: 19px !important; }
.font-size-27 { font-size: 20px !important; }
.font-size-28 { font-size: 21px !important; }
.font-size-29 { font-size: 21px !important; }
.font-size-30 { font-size: 22px !important; }
.font-size-33 { font-size: 23px !important; }
.font-size-34 { font-size: 24px !important; }
.font-size-35 { font-size: 25px !important; }
.font-size-36 { font-size: 26px !important; }
.font-size-38 { font-size: 27px !important; }
.font-size-40 { font-size: 29px !important; }
.font-size-45 { font-size: 31px !important; }
.font-size-50 { font-size: 35px !important; }
.font-size-55 { font-size: 37px !important; }
.font-size-68 { font-size: 46px !important; }
.font-size-72 { font-size: 49px !important; }

.font-weight-regular { font-weight: 400 !important; }
.font-weight-medium { font-weight: 500 !important; }
.font-weight-bold { font-weight: 700 !important; }

.c-white { color: var(--white) !important; }
.c-prussia { color: var(--prussia) !important; }
.c-celestino { color:  var(--celestino)!important; }
.c-carotina { color: var(--carotina) !important; }
.c-opale { color: var(--opale) !important; }
.c-red { color: var(--red) !important; }
.c-green { color: var(--green) !important; }
.c-overlay { color: var(--overlay) !important; }

.bg-app { background-color: var(--app) !important; }
.bg-transparent { background-color: transparent !important; }
.bg-white { background-color: var(--white) !important; }
.bg-prussia { background-color: var(--prussia) !important; }
.bg-celestino { background-color: var(--celestino) !important; }
.bg-carotina { background-color: var(--carotina) !important; }
.bg-opale { background-color: var(--opale) !important; }
.bg-red { background-color: var(--red) !important; }
.bg-green { background-color: var(--green) !important; }
.bg-alert { background-color: var(--alert-yellow) !important; }
.bg-alert-red { background-color: var(--alert-red) !important; }
.bg-overlay { background-color: var(--overlay) !important; }

.border-green { border: 1px solid var(--green) !important; }
.border-red { border: 1px solid var(--red) !important; }

.opacity-5 { opacity: .5; }
.opacity-7 { opacity: .7; }

.App {
    background-color: var(--app);
    margin-top: var(--nav-spacing);
    margin-left: var(--nav-spacing);
    overflow: auto;
}

.content-page {
    margin: 30px;
    padding: 40px;
}

.h-full-page-no-navbar { height: calc(100vh - var(--nav-spacing)); }
.h-10 { height: 10% !important; }
.h-20 { height: 20% !important; }
.h-30 { height: 30% !important; }
.h-40 { height: 40% !important; }

.rounded-l { border-radius: var(--rounded-l) !important; }
.rounded-m { border-radius: var(--rounded-m) !important; }

.shadow { box-shadow: 0px 0px 15px 0px var(--shadow) !important; }
.no-shadow { box-shadow: none !important; }

.disabled {
    pointer-events: none;
    background: var(--disabled) !important;
    border-color: var(--disabled) !important;
}

.custom-date-prussia::before {
    content: '\e901';
    position: relative;
    margin-right: 5px;
    top: 2px;
    font-size: 15px;
    font-family: 'e-icons';
    color: var(--prussia);
}

.custom-date-carotina::before {
    content: '\e901';
    position: relative;
    margin-right: 5px;
    top: 2px;
    font-size: 15px;
    font-family: 'e-icons';
    color: var(--carotina);
}

.custom-time-prussia::before {
    content: '\e20c';
    position: relative;
    margin-right: 5px;
    top: 2px;
    font-size: 15px;
    font-family: 'e-icons';
    color: var(--prussia);
}

.custom-time-carotina::before {
    content: '\e20c';
    position: relative;
    margin-right: 5px;
    top: 2px;
    font-size: 15px;
    font-family: 'e-icons';
    color: var(--carotina);
}

.fluidity-bg {
    background-image: url(./images/bg_fluidity.svg);
    background-repeat: no-repeat;
    background-position-x: right;
}

.omino {
    position: relative;
    width: 30px;
    bottom: 3px;
    left: 4px;
}

.omino.gray {
    position: relative;
    width: 22px;
    height: 22px;
    bottom: 7px;
    left: 4px;
}

